import Head from 'next/head';
import React, { ReactElement } from 'react';
import { IntlShape, useIntl } from 'react-intl';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  DefaultTheme,
  Heading,
  Spacing,
  Text,
  makeStyles
} from 'groundkeeper-component-library';

import AuthenticationArea from 'shared/App/components/AuthenticationArea';
import Layout from 'shared/App/components/Layout';
import Teaser from 'shared/App/components/Teaser';

import Globals from 'shared/App/utils/Globals';
import selectOrganizations from 'shared/Redux/selectors/organization/selectOrganizations';
import { getClientSessionToken } from 'shared/Redux/api/session';
import { useSelector } from 'react-redux';
import { FAQ } from './faq';
import Link from 'shared/App/components/Link';

function getRequestCreateUrl() {
  if (!!getClientSessionToken()) {
    return '/requests/create';
  } else {
    return '/login?redirectUrl=/requests/create';
  }
}

type TeaserList = Array<{
  id: string;
  icon: string;
  iconLib?: string;
  href: string;
  title: string;
  text: string;
  moreLink?: string;
}>;

const TEASER = (intl: IntlShape, portalBookingRequests: Boolean) => {
  const teaserList: TeaserList = [
    {
      id: 'groups-find',
      icon: 'search',
      href: 'groups?unbookable=false',
      title: intl.formatMessage({
        id: 'groups_find',
        defaultMessage: 'Find groups'
      }),
      text: intl.formatMessage({
        id: 'groups_find_hint',
        defaultMessage: 'Just look for a group'
      }),
      moreLink: intl.formatMessage({
        id: 'group_teaser_search_more_link',
        defaultMessage: 'To search'
      })
    }
  ];

  if (portalBookingRequests) {
    teaserList.push({
      id: 'request',
      icon: 'textbox-plus',
      title: intl.formatMessage({
        id: 'request_create',
        defaultMessage: 'Request create'
      }),
      href: getRequestCreateUrl(),
      text: intl.formatMessage({
        id: 'request_times_hint',
        defaultMessage: 'For sports organizations and other sponsors'
      }),
      moreLink: intl.formatMessage({
        id: 'group_teaser_request_more_link',
        defaultMessage: 'To request form'
      })
    });
  }

  teaserList.push({
    id: 'groups-explore',
    icon: 'map-marked',
    iconLib: 'fa',
    title: 'Sportanlagen entdecken',
    href: '/map',
    text: 'Sportanlagen und ihre Standorte auf einer Karte von Berlin entdecken',
    moreLink: intl.formatMessage({
      id: 'group_teaser_map_more_link',
      defaultMessage: 'To the map'
    })
  });

  return teaserList;
};

const useStyles = makeStyles((theme: DefaultTheme) => ({
  teaser: {
    display: 'flex',
    gap: theme.spacing(2),
    margin: 0,
    padding: 0,
    listStyleType: 'none',
    [theme.mediaQueries.tiny]: {
      flexDirection: 'column'
    },
    '& li': {
      width: '33%',
      [theme.mediaQueries.small]: {
        width: '100%'
      }
    }
  }
}));

function Index() {
  const intl = useIntl();
  const classes = useStyles();
  const organization = useSelector(selectOrganizations)?.[0];

  return (
    <>
      <Head>
        <title>{`${Globals.settings.titlePrefix}${intl.formatMessage({
          id: 'sports_portal',
          defaultMessage: 'Sports portal Berlin'
        })}${Globals.settings.titlePostfix}`}</title>
      </Head>
      <AuthenticationArea />
      <Spacing y={5} />
      <Heading type="h1">
        {intl.formatMessage({
          id: 'welcome_to_sports_portal',
          defaultMessage: 'Welcome to sports portal Berlin!'
        })}
      </Heading>
      <>
        <Spacing y={2} />
        <section>
          <ul className={classes.teaser}>
            {TEASER(intl, organization?.setting_portal_booking_requests).map(
              t => (
                <li key={t.id}>
                  <Teaser
                    title={t.title}
                    text={t.text}
                    icon={t.icon}
                    iconLib={t.iconLib}
                    href={t.href}
                    moreLink={t.moreLink}
                  />
                </li>
              )
            )}
          </ul>
        </section>
      </>
      <Spacing y={4} />
      <Heading type="h2">
        {intl.formatMessage({
          id: 'faq',
          defaultMessage: 'Frequently asked Questions (FAQ)'
        })}
      </Heading>
      <section>
        {FAQ[0].questions
          .filter((q, i) => i < FAQ.length)
          .map(q => {
            const id = encodeURIComponent(q.title);

            return (
              <Accordion key={id}>
                <AccordionSummary aria-controls={id} id={id}>
                  <Text>{q.title}</Text>
                </AccordionSummary>
                <AccordionDetails>{q.text}</AccordionDetails>
              </Accordion>
            );
          })}
        <Spacing y={2} />
        <Text>
          {intl.formatMessage(
            {
              id: 'faq_hint',
              defaultMessage: 'Further information at'
            },
            {
              page: (
                <Link href="/faq">
                  {intl.formatMessage({
                    id: 'page',
                    defaultMessage: 'Page'
                  })}
                </Link>
              )
            }
          )}
        </Text>
      </section>
    </>
  );
}

Index.getLayout = function getLayout(page: ReactElement) {
  return <Layout>{page}</Layout>;
};

export default Index;
